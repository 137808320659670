import { groq } from 'next-sanity';
import {
  ARTICLE_FILTER,
  CATEGORY_FILTER,
  CATEGORY_KEY_FILTER,
  DOC_ID_FILTER,
  LOCALE_FILTER,
  LOCALES_ARRAY_FILTER,
  ORDER_BY_PUBLISH_DATE_DESC,
  PUBLIC_FILTER,
  TAG_TITLE_FILTER,
} from '../filters';
import { BlogDocumentType } from '../types';
import {
  BLOCKS_BODY_FRAGMENT,
  DOCUMENT_FOR_PATH_FRAGMENT,
  IMAGE_FRAGMENT,
  TRANSLATIONS_FRAGMENT,
  PATHNAME_FRAGMENT,
} from './generic';

const AUTHORS_FRAGMENT = groq`
  authors[]->{
    _id,
    name,
    bio,
    image,
  },
`;

const CATEGORIES_FRAGMENT = groq`
  categories[]->{
    _id,
    title,
    ${PATHNAME_FRAGMENT}
  },
`;

const TAGS_FRAGMENT = groq`
  tags[]->{
    title,
    ${DOCUMENT_FOR_PATH_FRAGMENT}
  },
`;

const PUBLISHED_ARTICLES_FRAGMENT = groq`
 "articles": *[
    ${ARTICLE_FILTER} &&
    references(^._id) &&
    ${PUBLIC_FILTER}
  ]
`;

const FEATURED_IMAGE_FRAGMENT = groq`
 featuredImage { ${IMAGE_FRAGMENT} },
`;

const ALL_CATEGORIES_QUERY = groq`
"routeData": *[${CATEGORY_FILTER} && ${LOCALE_FILTER} && ${PUBLIC_FILTER} && count(*[
    ${ARTICLE_FILTER} &&
    references(^._id) &&
    ${PUBLIC_FILTER}
  ]) > 0] {
  ...,
  ${PATHNAME_FRAGMENT}
  "type": _type,
  title,
  key,
}`;

const ARTICLE_ROUTE_QUERY = groq`
  "routeData": *[${DOC_ID_FILTER}][0] {
    ...,
    body[] {
      ${BLOCKS_BODY_FRAGMENT}
    },
    ${FEATURED_IMAGE_FRAGMENT}
    ${AUTHORS_FRAGMENT}
    ${CATEGORIES_FRAGMENT}
    ${TAGS_FRAGMENT}
    ${TRANSLATIONS_FRAGMENT}
    "type": _type,
    "outlines": body[length(style) == 2 && string::startsWith(style, "h")]
  }
`;

const CATEGORY_ROUTE_QUERY = groq`
  "routeData": *[${DOC_ID_FILTER}][0] {
    ...,
    ${TRANSLATIONS_FRAGMENT}
    title,
    "type": _type,
    ${PUBLISHED_ARTICLES_FRAGMENT}{
    ...,
     ${PATHNAME_FRAGMENT}
     ${FEATURED_IMAGE_FRAGMENT}
     ${AUTHORS_FRAGMENT}
     ${CATEGORIES_FRAGMENT}
    } | ${ORDER_BY_PUBLISH_DATE_DESC}
  }`;

const CATEGORY_BY_KEY_ROUTE_QUERY = groq`
"routeData": *[${CATEGORY_KEY_FILTER} && ${LOCALES_ARRAY_FILTER}] {
  ...,
  ${TRANSLATIONS_FRAGMENT}
  title,
  key,
  "type": _type,
  ${PUBLISHED_ARTICLES_FRAGMENT}[0..2] | ${ORDER_BY_PUBLISH_DATE_DESC} {
  ...,
   ${PATHNAME_FRAGMENT}
   ${FEATURED_IMAGE_FRAGMENT}
   ${AUTHORS_FRAGMENT}
   ${CATEGORIES_FRAGMENT}
  }
}`;

const TAG_BY_TITLE_QUERY = groq`
"routeData": *[${TAG_TITLE_FILTER} && ${LOCALES_ARRAY_FILTER}] {
  ...,
  ${TRANSLATIONS_FRAGMENT}
  title,
  key,
  "type": _type,
  ${PUBLISHED_ARTICLES_FRAGMENT}[0..2] | ${ORDER_BY_PUBLISH_DATE_DESC} {
  ...,
   ${PATHNAME_FRAGMENT}
   ${FEATURED_IMAGE_FRAGMENT}
   ${AUTHORS_FRAGMENT}
   ${CATEGORIES_FRAGMENT}
  }
}`;

const BLOG_ARTICLE_CARD_FRAGMENT = groq`
  _id,
  _type,
  title,
  // Either the preamble or the first paragraph
  "preamble": select(
    defined(preamble) => preamble,
    pt::text(body[_type == "block"][0..2]),
  ),
  "publishedAt": coalesce(publishedAt, _createdAt),
  isFeatured,
  ${PATHNAME_FRAGMENT}
  ${FEATURED_IMAGE_FRAGMENT}
  ${TAGS_FRAGMENT}
  ${AUTHORS_FRAGMENT}
  ${CATEGORIES_FRAGMENT}
`;

const BLOG_TAG_FRAGMENT = groq`
  _type,
  _id,
  locale,
  slug,
  title,
  body[] {
    ${BLOCKS_BODY_FRAGMENT}
  },
`;

const BLOG_PATHS = groq`
*[
  _type in ["${BlogDocumentType.Category}", "${BlogDocumentType.Article}"] &&
  ${PUBLIC_FILTER} &&
  defined(pathname.current)
  ][]{
    ${PATHNAME_FRAGMENT}
    "locale": locale,
    "id": _id,
  }`;

export {
  BLOG_PATHS,
  ARTICLE_ROUTE_QUERY,
  TAG_BY_TITLE_QUERY,
  CATEGORY_ROUTE_QUERY,
  CATEGORY_BY_KEY_ROUTE_QUERY,
  BLOG_TAG_FRAGMENT,
  BLOG_ARTICLE_CARD_FRAGMENT,
  ALL_CATEGORIES_QUERY,
};
