import { groq } from 'next-sanity';
import { PUBLIC_FILTER } from '../filters';

const PATHNAME_FRAGMENT = groq`
  "pathname": pathname.current,
`;

const DOCUMENT_FOR_PATH_FRAGMENT = `
  "type": _type,
  "id": _id,
  locale,
  ${PATHNAME_FRAGMENT}
`;

const LINK_FRAGMENT = groq`
  newWindow,
  linkType,
  url,
  deepLink,
  utmParameters,
  rel,
  linkType == "internal" => {
    internalLink->{ ${DOCUMENT_FOR_PATH_FRAGMENT} },
},`;

const IMAGE_FRAGMENT = groq`
  asset->{
    "_ref": _id,
    "lqip": metadata.lqip
  }
`;

const RICH_PARAGRAPH_FRAGMENT = `
...,
markDefs[]{
  ...,
  _type == "link" => {
    ${LINK_FRAGMENT}
  }
},`;

const PORTABLE_TEXT_FRAGMENT = RICH_PARAGRAPH_FRAGMENT;

const FAQ_BLOCK_QUERY = groq`
...,
questions[] {
  _type == "reference" => @->{
    title,
    answer[] {
      ${PORTABLE_TEXT_FRAGMENT}
    }
  },
  _type == "customQuestion" => {
    title,
    answer[] {
      ${PORTABLE_TEXT_FRAGMENT}
    }
  }
}
`;

const HERO_BLOCK_QUERY = groq`
  ...,
  body[] {
    ${PORTABLE_TEXT_FRAGMENT}
  }
`;

const ROUTE_DOCUMENT_QUERY = groq`
{
  "docForRoute": *[
      _type in $typesWithRoutes &&
      pathname.current == $pathname &&
      locale == $locale &&
      ${PUBLIC_FILTER}
    ][0]{
      ${DOCUMENT_FOR_PATH_FRAGMENT}
    },
  }
`;

const CTA_FRAGMENT = `
_key,
label,
link {
  ${LINK_FRAGMENT}
},`;

const TRANSLATIONS_FRAGMENT = groq`
"translations": *[_type == "translation.metadata" && references(^._id)].translations[].value->{
  locale,
  "internalLink": { ${DOCUMENT_FOR_PATH_FRAGMENT} },
},
`;

const CTA_BLOCK_QUERY = groq`
...,
cta {
  ${CTA_FRAGMENT}
}
`;

const BLOCKS_BODY_FRAGMENT = `
...,
_type == "block.cta" => {
  ${CTA_BLOCK_QUERY}
},
_type == "block.faq" => {
  ${FAQ_BLOCK_QUERY}
},
_type == "block.hero" => {
  ${HERO_BLOCK_QUERY}
},
_type == "block" => {
  ${PORTABLE_TEXT_FRAGMENT}
},
_type == "block.image" => {
  ${IMAGE_FRAGMENT}
},
`;

export {
  DOCUMENT_FOR_PATH_FRAGMENT,
  TRANSLATIONS_FRAGMENT,
  BLOCKS_BODY_FRAGMENT,
  ROUTE_DOCUMENT_QUERY,
  IMAGE_FRAGMENT,
  PATHNAME_FRAGMENT,
};
