import { Badge, Heading, Stack, Text } from '@carvertical/ui';
import { Link } from 'components/common/Link';
import type { Article, ArticleCategory } from '../../types';
import { extractFirstEntry } from '../../utils';
import { RoundedImage } from '../common/RoundedImage';
import { ArticleDate } from './ArticleDate';
import { AuthorImage } from './AuthorImage';
import styles from './ArticleCard.module.scss';

type ArticleCardProps = {
  article: Article;
  category?: ArticleCategory;
};

const ArticleCard = ({ article, category: categoryFromProps }: ArticleCardProps) => {
  const category = categoryFromProps || extractFirstEntry(article.categories);
  const author = extractFirstEntry(article.authors);

  return (
    <Link to={article.pathname} className={styles.root}>
      <Stack gap={2} className={styles.wrapper} crossAxisAlign="stretch">
        <Stack gap={1} crossAxisAlign="stretch">
          <RoundedImage image={article.featuredImage} imageClassName={styles.image} />

          <Stack type="horizontal" crossAxisAlign="center">
            <Badge size="s">{category.title}</Badge>

            <ArticleDate date={article.publishedAt} />
          </Stack>
        </Stack>

        <Heading as="h2" variant="xs">
          {article.title}
        </Heading>

        <Stack type="horizontal" gap={1}>
          <AuthorImage image={author.image} size={24} name={author.name} />

          <Text>{author.name}</Text>
        </Stack>
      </Stack>
    </Link>
  );
};

export { ArticleCard };
